import { QueryCommand } from "@aws-sdk/lib-dynamodb";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import { AccountTenant } from "@veti/accounts-types";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import ddbDocClient from "../../../lib/ddbDocClient";

const useStyles = makeStyles(() => ({
  root: {
    "& .status-cell": {
      fontWeight: "600",
    },
    "& .status-cell.active": {
      color: "green",
    },
    "& .status-cell.demo": {
      color: "orange",
    },
    "& .status-cell.inactive": {
      color: "red",
    },
  },
}));

export const TenantsList = () => {
  const [tenants, setTenants] = useState<AccountTenant[] | undefined>(
    undefined
  );
  const [error, setError] = useState<string | null>(null);
  const classes = useStyles();

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await ddbDocClient.send(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          new QueryCommand({
            TableName: "AccountsAndPlans",
            IndexName: "GSI1",
            KeyConditionExpression: "SK = :tenant",
            ExpressionAttributeValues: {
              ":tenant": "T",
            },
          })
        );
        return response.Items as AccountTenant[];
      } catch (error: any) {
        setError(error);
      }
    };
    fetchTenants()
      .then(setTenants)
      .catch((error: any) => setError(error));
  }, []);

  const columns = [
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "id", headerName: "Tenant id", flex: 1.5 },
    { field: "country", headerName: "Country", flex: 0.2 },
    { field: "city", headerName: "City", flex: 0.8 },
    { field: "productCode", headerName: "Product", flex: 0.8 },
    { field: "planCode", headerName: "Plan", flex: 0.8 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      cellClassName: (params: any) =>
        clsx("status-cell", {
          active: params.value === "active",
          demo: params.value === "demo",
          inactive: params.value === "inactive",
        }),
    },
  ];

  return (
    <div>
      {error && <strong>{JSON.stringify(error)}</strong>}
      <Grid container spacing={2} direction="column">
        <Grid item>
          {tenants && (
            <div style={{ height: 600, width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }} className={classes.root}>
                  <DataGrid rows={tenants} columns={columns} />
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
