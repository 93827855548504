import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { MainLayoutV2 } from "src/components/Layout";
import { TenantsRoutes } from "src/features/Tenants";

const App = () => {
  return (
    <MainLayoutV2>
      <Outlet />
    </MainLayoutV2>
  );
};

export const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/tenants/*" element={<TenantsRoutes />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};
