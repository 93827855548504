import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

const TenantDashboard = () => {
  return (
    <Box mt={3}>
      <Typography variant="caption">
        (Thy will be a dashboard someday captain)
      </Typography>
    </Box>
  );
};

export default TenantDashboard;
