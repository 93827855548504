import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

export type SnackbarAlertProps = {
  open: boolean;
  message: string;
  onClose: () => any;
  severity?: string;
};

const Alert = (props: any) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
);

const SnackbarAlert = ({
  open,
  message,
  onClose,
  severity = "info",
}: SnackbarAlertProps) => {
  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
