import Container from "@material-ui/core/Container";
import React from "react";

import { Head } from "src/components/Head";

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
  description: string;
};

export const ContentLayout = ({
  children,
  title,
  description,
}: ContentLayoutProps) => {
  return (
    <Container maxWidth="lg">
      <Head title={title} description={description} />
      <h1>{title}</h1>
      {children}
    </Container>
  );
};
