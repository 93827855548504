import { withAuthenticator } from "@aws-amplify/ui-react";

import { AppProvider } from "src/providers/AppProvider";
import { AppRoutes } from "src/routes";

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default withAuthenticator(App);
