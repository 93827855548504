import React, { createContext, useCallback, useContext, useState } from "react";

import SnackbarAlert from "./SnackbarAlert";

const SnackbarAlertContext = createContext(
  ({ message = "", severity = "info" }) => {}
);

export const useSnackbarAlert = () => useContext(SnackbarAlertContext);

type SnackbarAlertProviderProps = {
  children: React.ReactNode;
};

const SnackbarAlertProvider = ({ children }: SnackbarAlertProviderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<string>("info");

  const showSnackbar = useCallback(({ message, severity = "info" }): void => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }, []);

  return (
    <SnackbarAlertContext.Provider value={showSnackbar}>
      {children}
      <SnackbarAlert
        open={open}
        onClose={() => setOpen(false)}
        message={message}
        severity={severity}
      />
    </SnackbarAlertContext.Provider>
  );
};

export default SnackbarAlertProvider;
