import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";
import { Auth } from "aws-amplify";

const ddbClient = new DynamoDBClient({
  region: "us-east-1",
  credentials: Auth.Credentials.get(),
});
const ddbDocClient = DynamoDBDocumentClient.from(ddbClient);

export default ddbDocClient;
