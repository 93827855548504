import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ContentLayout } from "src/components/Layout";

import CreateTenant from "../components/CreateTenant";
import TenantDashboard from "../components/TenantDashboard";
import { TenantsList } from "../components/TenantsList";

type TenantsProps = {
  title: string;
  action?: "list" | "create";
  description?: string;
};

export const Tenants = ({
  title,
  action,
  description = "Tenants management",
}: TenantsProps) => {
  const navigate = useNavigate();
  return (
    <ContentLayout title={title} description={description}>
      <Grid container spacing={2} direction="row">
        <Grid item>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => navigate("/tenants/create")}
          >
            Create New Tenant
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => navigate("/tenants/list")}>
            Fetch Tenants List
          </Button>
        </Grid>
      </Grid>

      {action === "list" ? (
        <TenantsList />
      ) : action === "create" ? (
        <CreateTenant />
      ) : (
        <TenantDashboard />
      )}
    </ContentLayout>
  );
};
