import { Route, Routes } from "react-router-dom";

import { Tenants } from "./Tenants";

export const TenantsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Tenants title="Tenants" />} />
      <Route
        path="create"
        element={<Tenants title="Create Tenant" action="create" />}
      />
      <Route
        path="list"
        element={<Tenants title="Tenants List" action="list" />}
      />
    </Routes>
  );
};
