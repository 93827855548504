import { StartExecutionOutput } from "@aws-sdk/client-sfn";
import { Box, Button, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { AccountCreateTenantInput } from "@veti/accounts-types";
import { API } from "aws-amplify";
import { useFormik } from "formik";
import React, { useState } from "react";
import ReactJson from "react-json-view";

import { useSnackbarAlert } from "src/components/SnackbarAlert/SnackbarAlertContext";

const CreateTenant = () => {
  const [response, setResponse] = useState<any>(null);
  const [executionStatus, setExecutionStatus] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const snackAlert = useSnackbarAlert();

  const createTenant = async (tenant: AccountCreateTenantInput) => {
    setExecutionStatus("RUNNING");
    try {
      const response: StartExecutionOutput = await API.post(
        apiName,
        "/tenants",
        {
          body: tenant,
        }
      );
      setResponse(response);
      await poll(response.executionArn);
    } catch (e: any) {
      if (e.response) {
        setResponse(e.response.data);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "CL",
      city: "",
      email: "",
      productCode: "VETI",
      planCode: "VETIPB",
      planPricingCode: "CL1",
      timezone: "America/Santiago",
      phone: "",
      company: "",
    },
    onSubmit: createTenant,
  });
  const apiName = "accounts_api";

  const poll = async (executionArn: string | undefined): Promise<void> => {
    try {
      const response = await API.get(
        apiName,
        `/tenants/creation-status/?executionArn=${executionArn}`,
        {}
      );
      if (response && response.status) {
        if (response.status === "RUNNING") {
          setTimeout(() => poll(executionArn), 1000);
        }
        setExecutionStatus(response.status);
      } else {
        setExecutionStatus(null);
        setError(`No response from backend`);
      }
    } catch (error: any) {
      setError(error);
    }
  };

  return (
    <Box width="100%" mt={3}>
      {executionStatus &&
        executionStatus === "SUCCEEDED" &&
        snackAlert({
          message: "Tenant created successfully",
          severity: "success",
        })}
      {executionStatus &&
        executionStatus === "FAILED" &&
        snackAlert({
          message: "Error creating tenant. See state machine output",
          severity: "error",
        })}
      {error && snackAlert({ message: error, severity: "error" })}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="First name"
                  disabled={executionStatus === "RUNNING"}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Last name"
                  disabled={executionStatus === "RUNNING"}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <TextField
              name="email"
              fullWidth
              variant="outlined"
              size="small"
              label="Email"
              disabled={executionStatus === "RUNNING"}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              required
            />
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="country"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Country"
                  disabled={executionStatus === "RUNNING"}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="city"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="City"
                  disabled={executionStatus === "RUNNING"}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="timezone"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Timezone"
                  disabled={executionStatus === "RUNNING"}
                  value={formik.values.timezone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.timezone && Boolean(formik.errors.timezone)
                  }
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="productCode"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Product"
                  value={formik.values.productCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.productCode &&
                    Boolean(formik.errors.productCode)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="planCode"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Plan"
                  value={formik.values.planCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.planCode && Boolean(formik.errors.planCode)
                  }
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  name="planPricingCode"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Pricing"
                  value={formik.values.planPricingCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.planPricingCode &&
                    Boolean(formik.errors.planPricingCode)
                  }
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Phone"
                  disabled={executionStatus === "RUNNING"}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="company"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Company"
                  disabled={executionStatus === "RUNNING"}
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={executionStatus === "RUNNING"}
              fullWidth
            >
              {executionStatus === "RUNNING"
                ? "Creating tenant..."
                : "Create Tenant"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateTenant;
