import Amplify from "aws-amplify";

const configureAmplify = () => {
  Amplify.configure({
    // OPTIONAL - if your API requires authentication
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: "us-east-1",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: "accounts_api",
          endpoint: process.env.REACT_APP_ACCOUNTS_API_URL,
        },
      ],
    },
  });
};

export default configureAmplify;
