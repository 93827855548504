import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";

import SnackbarAlertProvider from "src/components/SnackbarAlert/SnackbarAlertContext";

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div>
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.assign(window.location.origin)}
    >
      <SnackbarAlertProvider>
        <Router>{children}</Router>
      </SnackbarAlertProvider>
    </ErrorBoundary>
  );
};
